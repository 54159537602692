$primary: #3535f8;
$secondary: #646363;
$light: #cbdef9;
$veryLight: #e0ebfc;
$dark: #1e4c78;
$warning: #fd7e14;
$onHoverGreyBackground: #e5e5e5;

// Admin pallette
// https://hex.negraru.com/1E4C78
$primaryAdmin: #1F1F94; //40% shades
$lightAdmin: #A2B1C7; //20% shades
$darkAdmin: #0C1E30; //60% shades

// BUTTONS
$border: 2px solid rgb(244, 245, 247);

$font-family-sans-serif: 'Be Vietnam', 'Roboto', 'Open Sans';

$headings-font-family: 'VAGRoundedBT', 'Roboto', 'Open Sans';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px,
);

$dropdown-min-width: '4rem';
